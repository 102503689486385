ß<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md6>
                  <v-text-field
                    v-model="smsSearch.keySearch"
                    :label="$t('smsHistory.search')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('smsHistory.title')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblEmailList.expanded=[]">
            <v-data-table
              ref="tblEmailList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="emailHistoryHeaders"
              :items="smsList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  @click="isAdmin() ? props.expanded=!props.expanded : null"
                  @mouseover="isAdmin() ? props.expanded=true : null">
                  <td
                    v-for="(header, index) in emailHistoryHeaders"
                    :key="header.value + index">
                    <span v-if="header.value == 'dateCreated'">{{ formatDisplayDate(props.item[header.value]) }}</span>
                    <div
                      v-else-if="header.value == 'responseCode'"
                      class="text-xs-center">
                      <span>{{ props.item[header.value] }}</span>
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="smsPaginate.totalPage"
      :current-page="smsPaginate.currentPage"
      :row-per-page="smsPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
// import ToastType from 'enum/toastType'
// import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  components: {
  },
  data: () => ({
    emailHistoryHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        width: '3%',
        align: 'center'
      },
      {
        sortable: false,
        text: 'smsHistory.toPhone',
        value: 'toPhone'
      },
      {
        sortable: false,
        text: 'smsHistory.response',
        value: 'responseCode'
      },
      {
        sortable: false,
        text: 'smsHistory.smsid',
        value: 'smsid'
      },
      // {
      //   sortable: false,
      //   text: 'smsHistory.smsProvider',
      //   value: 'smsProvider'
      // },
      {
        sortable: false,
        text: 'emailHistory.dateCreated',
        value: 'dateCreated'
      },
      {
        sortable: false,
        text: 'smsHistory.content',
        value: 'content'
      }
    ],
    smsList: [],
    smsPaginate: {
      totalPage: 1,
      rowPerPage: 1,
      currentPage: 1,
      pageSize: 1
    },
    smsSearch: {
      keySearch: null,
      dateCreate: null
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT',
      'GET_SMS_HISTORY_LIST_DATA'
    ])
  },
  watch: {
    GET_SMS_HISTORY_LIST_DATA () {
      let res = this.GET_SMS_HISTORY_LIST_DATA
      let data = res.results
      this.smsPaginate.currentPage = res.paginate.currentPage
      this.smsPaginate.totalPage = res.paginate.totalPage
      this.smsPaginate.rowPerPage = res.paginate.perPage / 2
      // this.smsPaginate.pageSize = res.paginate.pageSize
      this.smsList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let item = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          supplier: data[i].supplier,
          agent: data[i].agent,
          userAction: data[i].user_created,
          dateCreated: data[i].date_created,
          toPhone: data[i].sent_phone,
          content: data[i].sent_content,
          responseCode: data[i].response_code,
          smsid: data[i].sent_smsid,
          smsProvider: data[i].provider_type
        }
        this.smsList.push(item)
      }
    }
  },
  created () {
    if (!this.isAdmin()) {
      this.emailHistoryHeaders = this.emailHistoryHeaders.filter((element) => {
        return element.value !== 'content'
      })
    }
    this.getEmailHistoryList()
  },
  methods: {
    parseAddressInfo: function (address) {
      if (functionUtils.isEmptyString(address)) {
        return null
      }
      return JSON.parse(address.replace(/'/g, '"'))
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    formatDisplayDate: function (date) {
      return dateUtils.formatBeautyDateTime(date, null)
    },
    onSearch: function () {
      this.getEmailHistoryList()
    },
    getEmailHistoryList: function () {
      let filter = {
        params: {
          page: this.smsPaginate.currentPage,
          keySearch: this.smsSearch.keySearch,
          dateCreate: this.smsSearch.dateCreate
        }
      }
      this.isLoading = true
      this.GET_SMS_HISTORY_LIST(filter).then(
        function (res) {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onPageChange: function (page) {
      this.smsPaginate.currentPage = page
      this.getEmailHistoryList()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_SMS_HISTORY_LIST'
    ])
  }
}
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
</style>
